<template>
  <div class="pc-header flex align-center justify-space-between">
    <div class="Safieicon pc-logo" @click="router.push('/')">
      <span class="Safieicon">&#xe615;</span>&#xe616;
    </div>
    <div class="pc-menu-bar flex align-center" v-if="isShow">
      <el-dropdown
        placement="bottom-start"
        popper-class="header-popper"
        @visible-change="handleVisibleChangeGuide"
        @command="handleCommand"
      >
        <div class="item">
          <div class="title">{{ t("layout.HEADER_GUIDE") }}</div>
          <span v-if="visibleGuide" class="line"></span>
          <span v-else class="Safieicon icon">&#xe611;</span>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="/tutorial/developers">{{
              t("layout.HEADER_TUTORIAL_DEVELOPERS")
            }}</el-dropdown-item>
            <el-dropdown-item command="/tutorial/oauth">{{
              t("layout.HEADER_TUTORIAL_OAUTH")
            }}</el-dropdown-item>
            <el-dropdown-item command="/tutorial/api-key">{{
              t("layout.HEADER_TUTORIAL_API_KEY")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown
        placement="bottom-start"
        popper-class="header-popper"
        @visible-change="handleVisibleDesignChange"
        @command="handleCommand"
      >
        <div class="item">
          <div class="title">{{ t("layout.HEADER_GUIDELINES") }}</div>
          <span v-if="visibleDesign" class="line"></span>
          <span v-else class="Safieicon icon">&#xe611;</span>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="/guidelines">{{
              t("layout.HEADER_API_GUIDELINES")
            }}</el-dropdown-item>
            <!-- TODO set real route -->
            <el-dropdown-item command="/guidelines/sdk">{{
              t("layout.HEADER_SDK_GUIDELINES")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown
        placement="bottom-start"
        popper-class="header-popper"
        @visible-change="handleVisibleChangeFaq"
        @command="handleFaqCommand"
      >
        <div class="item">
          <div class="title">{{ t("layout.HEADER_FAQ_MENU") }}</div>
          <span v-if="visibleFaq" class="line"></span>
          <span v-else class="Safieicon icon">&#xe611;</span>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="https://safie.jp/contact/api/"
              >{{ t("layout.HEADER_CONSULT") }}
              <span class="Safieicon icon_icon_external-link"></span
            ></el-dropdown-item>
            <el-dropdown-item command="faq">{{
              t("layout.HEADER_FAQ")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown
        placement="bottom-start"
        popper-class="header-popper"
        @visible-change="handleVisibleStartGuide"
        @command="handleCommand"
      >
        <div class="item">
          <div class="title">{{ t("layout.HEADER_REFERENCE") }}</div>
          <span v-if="visibleStartGuide" class="line"></span>
          <span v-else class="Safieicon icon">&#xe611;</span>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="/reference/api">{{
              t("layout.HEADER_API_REFERENCE")
            }}</el-dropdown-item>
            <el-dropdown-item command="/terms-of-api">{{
              t("layout.HEADER_API_RESTRICTION")
            }}</el-dropdown-item>
            <el-dropdown-item command="/reference/sdk">{{
              t("layout.HEADER_SDK_REFERENCE")
            }}</el-dropdown-item>
            <el-dropdown-item command="/terms-of-api/sdk">{{
              t("layout.HEADER_SDK_RESTRICTION")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div class="item" @click="toManage">
        <SafieButton class="btn" size="large-48"
          ><span class="Safieicon setting-icon">&#xe613;</span
          >{{ t("layout.HEADER_MANAGE") }}</SafieButton
        >
      </div>
      <div class="item" v-if="!isLogin" @click="login">
        <span class="Safieicon info-icon">&#xe635;</span>
        <div class="info">
          {{ t("layout.HEADER_LOGIN") }}
        </div>
      </div>
      <el-dropdown
        placement="bottom-end"
        trigger="click"
        popper-class="header-popper"
        @command="handleUserCommand"
        v-else
      >
        <div class="item">
          <span
            class="Safieicon info-icon"
            v-if="
              !store.state.currentAccountType ||
              store.state.currentAccountType === AccountType.user
            "
            >&#xe617;
          </span>
          <span
            class="Safieicon info-icon"
            v-if="store.state.currentAccountType === AccountType.corporation"
            >&#xe618;</span
          >
          <div class="info">{{ t("layout.HEADER_INFO_TAG") }}</div>
        </div>
        <template #dropdown>
          <div class="account flex align-center">
            {{ loginUsername }}
            <div
              class="tag"
              v-if="
                store.state.currentAccountType === AccountType.user &&
                store.state.userinfo?.corporation_developer
              "
            >
              {{ t("layout.HEADER_USER_TAG") }}
            </div>
            <div
              class="tag"
              v-if="
                store.state.currentAccountType === AccountType.corporation &&
                store.state.userinfo?.user_developer
              "
            >
              {{ t("layout.HEADER_CORPORATION_TAG") }}
            </div>
          </div>
          <el-dropdown-menu>
            <el-dropdown-item command="info">{{
              t("layout.HEADER_INFO")
            }}</el-dropdown-item>
            <el-dropdown-item
              command="user"
              v-if="
                store.state.currentAccountType === AccountType.corporation &&
                store.state.userinfo?.user_developer
              "
              >{{ t("layout.HEADER_USER") }}</el-dropdown-item
            >
            <el-dropdown-item
              command="corporation"
              v-if="
                store.state.currentAccountType === AccountType.user &&
                store.state.userinfo?.corporation_developer
              "
              >{{ t("layout.HEADER_CORPORATION") }}</el-dropdown-item
            >
          </el-dropdown-menu>
          <div class="logout" @click="logout">
            {{ t("layout.HEADER_LOGOUT") }}
          </div>
        </template>
      </el-dropdown>
    </div>
  </div>
  <div class="sp-header flex align-center justify-space-between">
    <div class="Safieicon sp-logo" @click="router.push('/')">
      &#xe615;<span v-if="!isShow">&#xe616;</span>
    </div>
    <div class="sp-menu-bar flex align-center" v-if="isShow">
      <SafieButton class="btn sp-setting-btn" size="large-48" @click="toManage"
        ><span class="Safieicon">&#xe613;</span
        >{{ t("layout.HEADER_MANAGE") }}</SafieButton
      >
      <div
        class="Safieicon sp-menu-btn flex align-center justify-center"
        ref="spMenuBtn"
        @click="handleOpenMenu('switch')"
      >
        &#xe63d;
      </div>
    </div>
  </div>
  <div class="sp-menu-back" v-if="isShowMenu"></div>
  <div class="sp-menu flex flex-column" v-if="isShowMenu" ref="spMenuRef">
    <el-collapse>
      <el-collapse-item :title="t('layout.HEADER_GUIDE')">
        <div
          class="sp-menu-item flex align-center"
          @click="handleCommand('/tutorial/developers')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="/tutorial/developers">
            {{ t("layout.HEADER_TUTORIAL_DEVELOPERS") }}
          </a>
        </div>
        <div
          class="sp-menu-item flex align-center"
          @click="handleCommand('/tutorial/oauth')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="/tutorial/oauth">
            {{ t("layout.HEADER_TUTORIAL_OAUTH") }}
          </a>
        </div>
        <div
          class="sp-menu-item flex align-center"
          @click="handleCommand('/tutorial/api-key')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="/tutorial/api-key">
            {{ t("layout.HEADER_TUTORIAL_API_KEY") }}
          </a>
        </div>
      </el-collapse-item>
      <el-collapse-item :title="t('layout.HEADER_GUIDELINES')">
        <div
          class="sp-menu-item flex align-center"
          @click="handleCommand('/guidelines')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="/guidelines">
            {{ t("layout.HEADER_API_GUIDELINES") }}
          </a>
        </div>
        <div
          class="sp-menu-item flex align-center"
          @click="handleCommand('/guidelines/sdk')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="/guidelines/sdk">
            {{ t("layout.HEADER_SDK_GUIDELINES") }}
          </a>
        </div>
      </el-collapse-item>
      <el-collapse-item :title="t('layout.HEADER_FAQ_MENU')">
        <div
          class="sp-menu-item flex align-center"
          @click="handleFaqCommand('https://safie.jp/contact/api/')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="https://safie.jp/contact/api/">
            {{ t("layout.HEADER_CONSULT") }}
            <span class="Safieicon">&#xe612;</span>
          </a>
        </div>
        <div
          class="sp-menu-item flex align-center"
          @click="handleFaqCommand('faq')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="/faq">{{ t("layout.HEADER_FAQ") }}</a>
        </div>
      </el-collapse-item>
      <el-collapse-item :title="t('layout.HEADER_REFERENCE')">
        <div
          class="sp-menu-item flex align-center"
          @click="handleCommand('/reference/api')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="/reference/api">
            {{ t("layout.HEADER_API_REFERENCE") }}
          </a>
        </div>
        <div
          class="sp-menu-item flex align-center"
          @click="handleCommand('/terms-of-api')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="/terms-of-api">
            {{ t("layout.HEADER_API_RESTRICTION") }}
          </a>
        </div>
        <div
          class="sp-menu-item flex align-center"
          @click="handleCommand('/reference/sdk')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="/reference/sdk">
            {{ t("layout.HEADER_SDK_REFERENCE") }}
          </a>
        </div>
        <div
          class="sp-menu-item flex align-center"
          @click="handleCommand('/terms-of-api/sdk')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="/terms-of-api/sdk">
            {{ t("layout.HEADER_SDK_RESTRICTION") }}
          </a>
        </div>
      </el-collapse-item>
      <el-collapse-item :title="t('layout.MENU_MANAGE_TITLE')" v-if="isLogin">
        <div
          class="sp-menu-item flex align-center"
          @click="sPToManage('/applications')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="/applications">
            {{ t("layout.MENU_APPLICATIONS_TITLE") }}
          </a>
          <div></div>
        </div>
        <div
          class="sp-menu-item flex align-center sp-menu-sub-item"
          @click="sPToManage('/applications/apikey-list')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="/applications/apikey-list">
            {{ t("layout.MENU_API_TITLE") }}
          </a>
          <div></div>
        </div>
        <div
          class="sp-menu-item flex align-center sp-menu-sub-item"
          @click="sPToManage('/applications/oauth-list')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="/applications/oauth-list">
            {{ t("layout.MENU_OAUTH_TITLE") }}
          </a>
        </div>
        <div
          class="sp-menu-item flex align-center"
          @click="sPToManage('/user-setting-edit')"
        >
          <div class="line"></div>
          <a @click.left.prevent href="/user-setting-edit">
            {{ t("layout.MENU_PROFILE_TITLE") }}
          </a>
        </div>
      </el-collapse-item>
      <el-collapse-item :title="t('layout.HEADER_ACCOUNT_MANAGEMENT')">
        <div
          class="sp-menu-item flex align-center"
          v-if="!isLogin"
          @click="login"
        >
          <div class="line"></div>
          <div>{{ t("layout.HEADER_LOGIN") }}</div>
        </div>
        <template v-else>
          <div
            class="sp-menu-item flex align-center"
            @click="handleUserCommand('info')"
          >
            <div class="line"></div>
            <a @click.left.prevent href="/user-setting-edit">
              {{ t("layout.HEADER_INFO") }}
            </a>
          </div>
          <div
            class="sp-menu-item flex align-center"
            v-if="
              store.state.currentAccountType === AccountType.corporation &&
              store.state.userinfo?.user_developer
            "
            @click="handleUserCommand('user')"
          >
            <div class="line"></div>
            <div>{{ t("layout.HEADER_USER") }}</div>
          </div>
          <div
            class="sp-menu-item flex align-center"
            v-if="
              store.state.currentAccountType === AccountType.user &&
              store.state.userinfo?.corporation_developer
            "
            @click="handleUserCommand('corporation')"
          >
            <div class="line"></div>
            <div>{{ t("layout.HEADER_CORPORATION") }}</div>
          </div>
          <div class="sp-menu-item flex align-center" @click="logout">
            <div class="line"></div>
            <div>{{ t("layout.HEADER_LOGOUT") }}</div>
          </div>
        </template>
      </el-collapse-item>
    </el-collapse>
    <div class="sp-close-menu" @click="handleOpenMenu('close')">
      <span class="Safieicon">&#xe636;</span>
      <span>
        {{ t("global.CLOSE_BTN") }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { useStore, AccountType } from "@/store";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import services from "@/api/services";
import SafieButton from "@/common/components/SafieButton.vue";
import { showErrorCondition } from "@/utils";
defineProps<{
  isShow?: boolean;
}>();
const store = useStore();
const router = useRouter();
const { t } = useI18n();
const visibleGuide = ref(false);
const visibleFaq = ref(false);
const isShowMenu = ref(false);
const isLogin = computed(() => store.state.isLogin);
const loginUsername = computed(() => store.state.loginUsername);

const spMenuRef = ref<HTMLDivElement>();
const spMenuBtn = ref<HTMLDivElement>();
window.onclick = (event: MouseEvent) => {
  const target = event.target;
  if (
    spMenuBtn.value &&
    spMenuRef.value &&
    target &&
    isShowMenu.value &&
    target !== spMenuBtn.value &&
    target !== spMenuRef.value &&
    !spMenuRef.value?.contains(target as Node)
  ) {
    isShowMenu.value = false;
  }
};
const handleVisibleChangeGuide = (val: boolean) => {
  visibleGuide.value = val;
};
const handleVisibleChangeFaq = (val: boolean) => {
  visibleFaq.value = val;
};

const visibleDesign = ref(false);
function handleVisibleDesignChange(val: boolean) {
  visibleDesign.value = val;
}

const visibleStartGuide = ref(false);
function handleVisibleStartGuide(val: boolean) {
  visibleStartGuide.value = val;
}

const handleOpenMenu = (type: string) => {
  if (type === "switch") {
    isShowMenu.value = !isShowMenu.value;
  } else if (type === "close") {
    isShowMenu.value = false;
  }
};
const sPToManage = (routers: string) => {
  router.push({ path: routers });
  handleOpenMenu("close");
};
const handleUserCommand = (command: string | number | object) => {
  handleOpenMenu("close");
  if (command === "user") {
    store.commit("setCurrentAccountType", AccountType.user);
    store.commit(
      "setDeveloperId",
      store.state.userinfo?.user_developer?.developer_id,
    );
    store.commit(
      "setIsDedicatedOptionEnabledDeveloper",
      store.state.userinfo?.user_developer?.dedicated_option_enabled,
    );
  } else if (command === "corporation") {
    store.commit("setCurrentAccountType", AccountType.corporation);
    store.commit(
      "setDeveloperId",
      store.state.userinfo?.corporation_developer?.developer_id,
    );
    store.commit(
      "setIsDedicatedOptionEnabledDeveloper",
      store.state.userinfo?.corporation_developer?.dedicated_option_enabled,
    );
  } else if (command === "info") {
    router.push({ path: "/user-setting-edit" });
  }
};
const handleCommand = (command: string) => {
  router.push({ path: command });
  handleOpenMenu("close");
};
const handleFaqCommand = (command: string) => {
  if (command === "faq") {
    router.push({ path: "/faq" });
  } else {
    window.open(command);
  }
  handleOpenMenu("close");
};
const logout = () => {
  services.dev
    .devLogoutPost()
    .then(() => {
      handleOpenMenu("close");
      router.push({ path: "/" });
    })
    .catch((reason) => {
      if (reason.response.status === 428) {
        handleOpenMenu("close");
        router.push({ path: "/" });
        store.commit("setUserinfo", undefined);
        store.commit("setDeveloperId", undefined);
        store.commit("setLoginUsername", undefined);
        store.commit("setInitPassword", undefined);
        store.commit("setIsLogin", false);
      } else if (showErrorCondition(reason)) {
        store.commit("openErrorModal", {
          message: reason.response.data.message,
        });
      }
    });
};
const login = () => {
  handleOpenMenu("close");
  router.push({ path: "/login" });
};
const toManage = () => {
  router.push({ path: "/applications" });
};
</script>
<style lang="scss" scoped>
.pc-header {
  height: 80px;
  padding: 0 40px;
  .pc-logo {
    font-size: 28px;
    color: var(--safie-active-text);
    cursor: pointer;
    .Safieicon {
      color: var(--safie-green-400);
      font-size: 28px;
      margin-right: 12px;
    }
  }
  .pc-menu-bar {
    height: 80px;
    .item {
      padding: 0 12px;
      text-align: center;
      position: relative;
      cursor: pointer;
      .title {
        color: #505c58;
        font-size: 15px;
        line-height: 22px;
        font-weight: 700;
        text-decoration: none;
        &:active,
        &:hover,
        &:visited {
          color: #505c58;
        }
        .Safieicon {
          padding-left: 6px;
          color: var(--safie-green-400);
        }
      }
      .icon {
        position: absolute;
        left: 12px;
        right: 12px;
        text-align: center;
        pointer-events: none;
        color: var(--safie-green-400);
      }
      .line {
        position: absolute;
        left: 12px;
        right: 12px;
        height: 4px;
        background: var(--safie-green-400);
        top: 32px;
      }
      .info-icon {
        font-size: 32px;
        color: var(--safie-green-400);
      }
      .info {
        color: var(--safie-green-400);
        font-size: 12px;
        font-weight: 500;
      }
      .btn {
        background: linear-gradient(to right, var(--safie-green-400), #2578b2);
        width: 151px;
        span {
          padding-right: 8px;
        }
        .setting-icon {
          font-size: 20px;
        }
      }
    }
  }
}
.account {
  background: var(--safie-green-400);
  padding: 0px 24px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  height: 50px;
  .tag {
    height: 25px;
    display: inline-flex;
    align-items: center;
    border: 1px solid var(--safie-green-100);
    border-radius: 4px;
    padding: 0 8px;
    margin-left: 8px;
    font-size: 12px;
  }
}
.logout {
  line-height: 46px;
  color: var(--safie-green-400);
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  background: var(--safie-white-100);
  cursor: pointer;
}
:deep(.el-dropdown-menu__item) {
  padding: 12px 24px;
  font-size: 15px;
  font-weight: 700;
  color: #505c58;
  span {
    color: var(--safie-green-400);
    padding-left: 5px;
  }
}
:deep(.el-collapse-item__header) {
  background: none;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  height: 56px;
  border-bottom: 1px solid var(--safie-green-500);
}
:deep(.el-collapse) {
  border: none;
}
:deep(.el-collapse-item__wrap) {
  background: none;
}
:deep(.el-collapse-item__content) {
  padding-top: 8px;
  padding-bottom: 8px;
}
:deep(.el-collapse-item__wrap) {
  border-bottom: 1px solid var(--safie-green-500);
}
:deep(.el-collapse-item__arrow) {
  transform: rotate(90deg);
}
:deep(.el-collapse-item) {
  &.api-link-item {
    .el-collapse-item__arrow {
      transform: rotate(0deg);
    }
    a {
      text-decoration: none;
      color: inherit;
      &:active,
      &:hover,
      &:visited {
        color: inherit;
      }
    }
  }
}

:deep(.el-collapse-item__arrow.is-active) {
  transform: rotate(-90deg);
}
.sp-header {
  display: none !important;
  height: 80px;
  padding-left: 16px;
  .sp-logo {
    font-size: 28px;
    color: var(--safie-green-400);
    cursor: pointer;
    span {
      color: var(--safie-active-text);
      padding-left: 12px;
    }
  }
  .sp-menu-bar {
    .btn.sp-setting-btn {
      padding-left: 16px;
      padding-right: 16px;
      .Safieicon {
        font-size: 20px;
        margin-right: 8px;
      }
    }
    .sp-menu-btn {
      cursor: pointer;
      height: 80px;
      width: 80px;
      background: var(--safie-green-400);
      font-size: 40px;
      margin-left: 8px;
      color: #fff;
    }
    .btn {
      background: linear-gradient(to right, var(--safie-green-400), #2578b2);
    }
  }
}
.sp-menu-back {
  content: "";
  z-index: 9;
  position: fixed;
  top: 81px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.sp-menu {
  position: fixed;
  left: 0;
  top: 81px;
  right: 0;
  background: var(--safie-green-400);
  padding: 0 16px;
  z-index: 10;
  max-height: calc(100% - 81px);
  overflow-y: auto;

  .sp-close-menu {
    color: var(--safie-green-100);
    line-height: 24px;
    text-align: center;
    padding: 24px 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    .Safieicon {
      font-size: 24px;
      margin-right: 8px;
    }
  }
  .sp-menu-item {
    cursor: pointer;
    line-height: 26px;
    padding: 10px 0;
    font-size: 14px;
    color: var(--safie-green-100);
    a {
      text-decoration: none;
      color: inherit;
      &:active,
      &:hover,
      &:visited {
        color: inherit;
      }
    }
    .line {
      width: 8px;
      height: 1px;
      background: var(--safie-green-100);
      margin-right: 8px;
    }
    .Safieicon {
      color: var(--safie-green-100);
    }
  }
  .sp-menu-sub-item {
    padding-left: 10px;
  }
}
@media screen and (max-width: 996px) {
  .pc-header {
    display: none !important;
  }
  .sp-header {
    display: flex !important;
  }
}
</style>
