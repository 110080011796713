import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pc-header flex align-center justify-space-between" }
const _hoisted_2 = {
  key: 0,
  class: "pc-menu-bar flex align-center"
}
const _hoisted_3 = { class: "item" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = {
  key: 0,
  class: "line"
}
const _hoisted_6 = {
  key: 1,
  class: "Safieicon icon"
}
const _hoisted_7 = { class: "item" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = {
  key: 0,
  class: "line"
}
const _hoisted_10 = {
  key: 1,
  class: "Safieicon icon"
}
const _hoisted_11 = { class: "item" }
const _hoisted_12 = { class: "title" }
const _hoisted_13 = {
  key: 0,
  class: "line"
}
const _hoisted_14 = {
  key: 1,
  class: "Safieicon icon"
}
const _hoisted_15 = { class: "item" }
const _hoisted_16 = { class: "title" }
const _hoisted_17 = {
  key: 0,
  class: "line"
}
const _hoisted_18 = {
  key: 1,
  class: "Safieicon icon"
}
const _hoisted_19 = { class: "info" }
const _hoisted_20 = { class: "item" }
const _hoisted_21 = {
  key: 0,
  class: "Safieicon info-icon"
}
const _hoisted_22 = {
  key: 1,
  class: "Safieicon info-icon"
}
const _hoisted_23 = { class: "info" }
const _hoisted_24 = { class: "account flex align-center" }
const _hoisted_25 = {
  key: 0,
  class: "tag"
}
const _hoisted_26 = {
  key: 1,
  class: "tag"
}
const _hoisted_27 = { class: "sp-header flex align-center justify-space-between" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = {
  key: 0,
  class: "sp-menu-bar flex align-center"
}
const _hoisted_30 = {
  key: 0,
  class: "sp-menu-back"
}

import { ref, computed } from "vue";
import { useStore, AccountType } from "@/store";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import services from "@/api/services";
import SafieButton from "@/common/components/SafieButton.vue";
import { showErrorCondition } from "@/utils";

export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  props: {
    isShow: { type: Boolean }
  },
  setup(__props: any) {


const store = useStore();
const router = useRouter();
const { t } = useI18n();
const visibleGuide = ref(false);
const visibleFaq = ref(false);
const isShowMenu = ref(false);
const isLogin = computed(() => store.state.isLogin);
const loginUsername = computed(() => store.state.loginUsername);

const spMenuRef = ref<HTMLDivElement>();
const spMenuBtn = ref<HTMLDivElement>();
window.onclick = (event: MouseEvent) => {
  const target = event.target;
  if (
    spMenuBtn.value &&
    spMenuRef.value &&
    target &&
    isShowMenu.value &&
    target !== spMenuBtn.value &&
    target !== spMenuRef.value &&
    !spMenuRef.value?.contains(target as Node)
  ) {
    isShowMenu.value = false;
  }
};
const handleVisibleChangeGuide = (val: boolean) => {
  visibleGuide.value = val;
};
const handleVisibleChangeFaq = (val: boolean) => {
  visibleFaq.value = val;
};

const visibleDesign = ref(false);
function handleVisibleDesignChange(val: boolean) {
  visibleDesign.value = val;
}

const visibleStartGuide = ref(false);
function handleVisibleStartGuide(val: boolean) {
  visibleStartGuide.value = val;
}

const handleOpenMenu = (type: string) => {
  if (type === "switch") {
    isShowMenu.value = !isShowMenu.value;
  } else if (type === "close") {
    isShowMenu.value = false;
  }
};
const sPToManage = (routers: string) => {
  router.push({ path: routers });
  handleOpenMenu("close");
};
const handleUserCommand = (command: string | number | object) => {
  handleOpenMenu("close");
  if (command === "user") {
    store.commit("setCurrentAccountType", AccountType.user);
    store.commit(
      "setDeveloperId",
      store.state.userinfo?.user_developer?.developer_id,
    );
    store.commit(
      "setIsDedicatedOptionEnabledDeveloper",
      store.state.userinfo?.user_developer?.dedicated_option_enabled,
    );
  } else if (command === "corporation") {
    store.commit("setCurrentAccountType", AccountType.corporation);
    store.commit(
      "setDeveloperId",
      store.state.userinfo?.corporation_developer?.developer_id,
    );
    store.commit(
      "setIsDedicatedOptionEnabledDeveloper",
      store.state.userinfo?.corporation_developer?.dedicated_option_enabled,
    );
  } else if (command === "info") {
    router.push({ path: "/user-setting-edit" });
  }
};
const handleCommand = (command: string) => {
  router.push({ path: command });
  handleOpenMenu("close");
};
const handleFaqCommand = (command: string) => {
  if (command === "faq") {
    router.push({ path: "/faq" });
  } else {
    window.open(command);
  }
  handleOpenMenu("close");
};
const logout = () => {
  services.dev
    .devLogoutPost()
    .then(() => {
      handleOpenMenu("close");
      router.push({ path: "/" });
    })
    .catch((reason) => {
      if (reason.response.status === 428) {
        handleOpenMenu("close");
        router.push({ path: "/" });
        store.commit("setUserinfo", undefined);
        store.commit("setDeveloperId", undefined);
        store.commit("setLoginUsername", undefined);
        store.commit("setInitPassword", undefined);
        store.commit("setIsLogin", false);
      } else if (showErrorCondition(reason)) {
        store.commit("openErrorModal", {
          message: reason.response.data.message,
        });
      }
    });
};
const login = () => {
  handleOpenMenu("close");
  router.push({ path: "/login" });
};
const toManage = () => {
  router.push({ path: "/applications" });
};

return (_ctx: any,_cache: any) => {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "Safieicon pc-logo",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/')))
      }, _cache[38] || (_cache[38] = [
        _createElementVNode("span", { class: "Safieicon" }, "", -1),
        _createTextVNode(" ")
      ])),
      (_ctx.isShow)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_el_dropdown, {
              placement: "bottom-start",
              "popper-class": "header-popper",
              onVisibleChange: handleVisibleChangeGuide,
              onCommand: handleCommand
            }, {
              dropdown: _withCtx(() => [
                _createVNode(_component_el_dropdown_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_dropdown_item, { command: "/tutorial/developers" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_TUTORIAL_DEVELOPERS")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_dropdown_item, { command: "/tutorial/oauth" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_TUTORIAL_OAUTH")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_dropdown_item, { command: "/tutorial/api-key" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_TUTORIAL_API_KEY")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)("layout.HEADER_GUIDE")), 1),
                  (visibleGuide.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5))
                    : (_openBlock(), _createElementBlock("span", _hoisted_6, ""))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown, {
              placement: "bottom-start",
              "popper-class": "header-popper",
              onVisibleChange: handleVisibleDesignChange,
              onCommand: handleCommand
            }, {
              dropdown: _withCtx(() => [
                _createVNode(_component_el_dropdown_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_dropdown_item, { command: "/guidelines" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_API_GUIDELINES")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_dropdown_item, { command: "/guidelines/sdk" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_SDK_GUIDELINES")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(t)("layout.HEADER_GUIDELINES")), 1),
                  (visibleDesign.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9))
                    : (_openBlock(), _createElementBlock("span", _hoisted_10, ""))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown, {
              placement: "bottom-start",
              "popper-class": "header-popper",
              onVisibleChange: handleVisibleChangeFaq,
              onCommand: handleFaqCommand
            }, {
              dropdown: _withCtx(() => [
                _createVNode(_component_el_dropdown_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_dropdown_item, { command: "https://safie.jp/contact/api/" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_CONSULT")) + " ", 1),
                        _cache[39] || (_cache[39] = _createElementVNode("span", { class: "Safieicon icon_icon_external-link" }, null, -1))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_dropdown_item, { command: "faq" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_FAQ")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(t)("layout.HEADER_FAQ_MENU")), 1),
                  (visibleFaq.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13))
                    : (_openBlock(), _createElementBlock("span", _hoisted_14, ""))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown, {
              placement: "bottom-start",
              "popper-class": "header-popper",
              onVisibleChange: handleVisibleStartGuide,
              onCommand: handleCommand
            }, {
              dropdown: _withCtx(() => [
                _createVNode(_component_el_dropdown_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_dropdown_item, { command: "/reference/api" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_API_REFERENCE")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_dropdown_item, { command: "/terms-of-api" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_API_RESTRICTION")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_dropdown_item, { command: "/reference/sdk" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_SDK_REFERENCE")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_dropdown_item, { command: "/terms-of-api/sdk" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_SDK_RESTRICTION")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(t)("layout.HEADER_REFERENCE")), 1),
                  (visibleStartGuide.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_17))
                    : (_openBlock(), _createElementBlock("span", _hoisted_18, ""))
                ])
              ]),
              _: 1
            }),
            _createElementVNode("div", {
              class: "item",
              onClick: toManage
            }, [
              _createVNode(SafieButton, {
                class: "btn",
                size: "large-48"
              }, {
                default: _withCtx(() => [
                  _cache[40] || (_cache[40] = _createElementVNode("span", { class: "Safieicon setting-icon" }, "", -1)),
                  _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_MANAGE")), 1)
                ]),
                _: 1
              })
            ]),
            (!isLogin.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "item",
                  onClick: login
                }, [
                  _cache[41] || (_cache[41] = _createElementVNode("span", { class: "Safieicon info-icon" }, "", -1)),
                  _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(t)("layout.HEADER_LOGIN")), 1)
                ]))
              : (_openBlock(), _createBlock(_component_el_dropdown, {
                  key: 1,
                  placement: "bottom-end",
                  trigger: "click",
                  "popper-class": "header-popper",
                  onCommand: handleUserCommand
                }, {
                  dropdown: _withCtx(() => [
                    _createElementVNode("div", _hoisted_24, [
                      _createTextVNode(_toDisplayString(loginUsername.value) + " ", 1),
                      (
                _unref(store).state.currentAccountType === _unref(AccountType).user &&
                _unref(store).state.userinfo?.corporation_developer
              )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(_unref(t)("layout.HEADER_USER_TAG")), 1))
                        : _createCommentVNode("", true),
                      (
                _unref(store).state.currentAccountType === _unref(AccountType).corporation &&
                _unref(store).state.userinfo?.user_developer
              )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(_unref(t)("layout.HEADER_CORPORATION_TAG")), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createVNode(_component_el_dropdown_menu, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_dropdown_item, { command: "info" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_INFO")), 1)
                          ]),
                          _: 1
                        }),
                        (
                _unref(store).state.currentAccountType === _unref(AccountType).corporation &&
                _unref(store).state.userinfo?.user_developer
              )
                          ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                              key: 0,
                              command: "user"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_USER")), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (
                _unref(store).state.currentAccountType === _unref(AccountType).user &&
                _unref(store).state.userinfo?.corporation_developer
              )
                          ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                              key: 1,
                              command: "corporation"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_CORPORATION")), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", {
                      class: "logout",
                      onClick: logout
                    }, _toDisplayString(_unref(t)("layout.HEADER_LOGOUT")), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_20, [
                      (
              !_unref(store).state.currentAccountType ||
              _unref(store).state.currentAccountType === _unref(AccountType).user
            )
                        ? (_openBlock(), _createElementBlock("span", _hoisted_21, " "))
                        : _createCommentVNode("", true),
                      (_unref(store).state.currentAccountType === _unref(AccountType).corporation)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_22, ""))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(t)("layout.HEADER_INFO_TAG")), 1)
                    ])
                  ]),
                  _: 1
                }))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_27, [
      _createElementVNode("div", {
        class: "Safieicon sp-logo",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/')))
      }, [
        _cache[42] || (_cache[42] = _createTextVNode(" ")),
        (!_ctx.isShow)
          ? (_openBlock(), _createElementBlock("span", _hoisted_28, ""))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isShow)
        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
            _createVNode(SafieButton, {
              class: "btn sp-setting-btn",
              size: "large-48",
              onClick: toManage
            }, {
              default: _withCtx(() => [
                _cache[43] || (_cache[43] = _createElementVNode("span", { class: "Safieicon" }, "", -1)),
                _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_MANAGE")), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", {
              class: "Safieicon sp-menu-btn flex align-center justify-center",
              ref_key: "spMenuBtn",
              ref: spMenuBtn,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (handleOpenMenu('switch')))
            }, "  ", 512)
          ]))
        : _createCommentVNode("", true)
    ]),
    (isShowMenu.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_30))
      : _createCommentVNode("", true),
    (isShowMenu.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "sp-menu flex flex-column",
          ref_key: "spMenuRef",
          ref: spMenuRef
        }, [
          _createVNode(_component_el_collapse, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_collapse_item, {
                title: _unref(t)('layout.HEADER_GUIDE')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "sp-menu-item flex align-center",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (handleCommand('/tutorial/developers')))
                  }, [
                    _cache[44] || (_cache[44] = _createElementVNode("div", { class: "line" }, null, -1)),
                    _createElementVNode("a", {
                      onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["left","prevent"])),
                      href: "/tutorial/developers"
                    }, _toDisplayString(_unref(t)("layout.HEADER_TUTORIAL_DEVELOPERS")), 1)
                  ]),
                  _createElementVNode("div", {
                    class: "sp-menu-item flex align-center",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (handleCommand('/tutorial/oauth')))
                  }, [
                    _cache[45] || (_cache[45] = _createElementVNode("div", { class: "line" }, null, -1)),
                    _createElementVNode("a", {
                      onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["left","prevent"])),
                      href: "/tutorial/oauth"
                    }, _toDisplayString(_unref(t)("layout.HEADER_TUTORIAL_OAUTH")), 1)
                  ]),
                  _createElementVNode("div", {
                    class: "sp-menu-item flex align-center",
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (handleCommand('/tutorial/api-key')))
                  }, [
                    _cache[46] || (_cache[46] = _createElementVNode("div", { class: "line" }, null, -1)),
                    _createElementVNode("a", {
                      onClick: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["left","prevent"])),
                      href: "/tutorial/api-key"
                    }, _toDisplayString(_unref(t)("layout.HEADER_TUTORIAL_API_KEY")), 1)
                  ])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_el_collapse_item, {
                title: _unref(t)('layout.HEADER_GUIDELINES')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "sp-menu-item flex align-center",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (handleCommand('/guidelines')))
                  }, [
                    _cache[47] || (_cache[47] = _createElementVNode("div", { class: "line" }, null, -1)),
                    _createElementVNode("a", {
                      onClick: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["left","prevent"])),
                      href: "/guidelines"
                    }, _toDisplayString(_unref(t)("layout.HEADER_API_GUIDELINES")), 1)
                  ]),
                  _createElementVNode("div", {
                    class: "sp-menu-item flex align-center",
                    onClick: _cache[12] || (_cache[12] = ($event: any) => (handleCommand('/guidelines/sdk')))
                  }, [
                    _cache[48] || (_cache[48] = _createElementVNode("div", { class: "line" }, null, -1)),
                    _createElementVNode("a", {
                      onClick: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["left","prevent"])),
                      href: "/guidelines/sdk"
                    }, _toDisplayString(_unref(t)("layout.HEADER_SDK_GUIDELINES")), 1)
                  ])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_el_collapse_item, {
                title: _unref(t)('layout.HEADER_FAQ_MENU')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "sp-menu-item flex align-center",
                    onClick: _cache[14] || (_cache[14] = ($event: any) => (handleFaqCommand('https://safie.jp/contact/api/')))
                  }, [
                    _cache[50] || (_cache[50] = _createElementVNode("div", { class: "line" }, null, -1)),
                    _createElementVNode("a", {
                      onClick: _cache[13] || (_cache[13] = _withModifiers(() => {}, ["left","prevent"])),
                      href: "https://safie.jp/contact/api/"
                    }, [
                      _createTextVNode(_toDisplayString(_unref(t)("layout.HEADER_CONSULT")) + " ", 1),
                      _cache[49] || (_cache[49] = _createElementVNode("span", { class: "Safieicon" }, "", -1))
                    ])
                  ]),
                  _createElementVNode("div", {
                    class: "sp-menu-item flex align-center",
                    onClick: _cache[16] || (_cache[16] = ($event: any) => (handleFaqCommand('faq')))
                  }, [
                    _cache[51] || (_cache[51] = _createElementVNode("div", { class: "line" }, null, -1)),
                    _createElementVNode("a", {
                      onClick: _cache[15] || (_cache[15] = _withModifiers(() => {}, ["left","prevent"])),
                      href: "/faq"
                    }, _toDisplayString(_unref(t)("layout.HEADER_FAQ")), 1)
                  ])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_el_collapse_item, {
                title: _unref(t)('layout.HEADER_REFERENCE')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "sp-menu-item flex align-center",
                    onClick: _cache[18] || (_cache[18] = ($event: any) => (handleCommand('/reference/api')))
                  }, [
                    _cache[52] || (_cache[52] = _createElementVNode("div", { class: "line" }, null, -1)),
                    _createElementVNode("a", {
                      onClick: _cache[17] || (_cache[17] = _withModifiers(() => {}, ["left","prevent"])),
                      href: "/reference/api"
                    }, _toDisplayString(_unref(t)("layout.HEADER_API_REFERENCE")), 1)
                  ]),
                  _createElementVNode("div", {
                    class: "sp-menu-item flex align-center",
                    onClick: _cache[20] || (_cache[20] = ($event: any) => (handleCommand('/terms-of-api')))
                  }, [
                    _cache[53] || (_cache[53] = _createElementVNode("div", { class: "line" }, null, -1)),
                    _createElementVNode("a", {
                      onClick: _cache[19] || (_cache[19] = _withModifiers(() => {}, ["left","prevent"])),
                      href: "/terms-of-api"
                    }, _toDisplayString(_unref(t)("layout.HEADER_API_RESTRICTION")), 1)
                  ]),
                  _createElementVNode("div", {
                    class: "sp-menu-item flex align-center",
                    onClick: _cache[22] || (_cache[22] = ($event: any) => (handleCommand('/reference/sdk')))
                  }, [
                    _cache[54] || (_cache[54] = _createElementVNode("div", { class: "line" }, null, -1)),
                    _createElementVNode("a", {
                      onClick: _cache[21] || (_cache[21] = _withModifiers(() => {}, ["left","prevent"])),
                      href: "/reference/sdk"
                    }, _toDisplayString(_unref(t)("layout.HEADER_SDK_REFERENCE")), 1)
                  ]),
                  _createElementVNode("div", {
                    class: "sp-menu-item flex align-center",
                    onClick: _cache[24] || (_cache[24] = ($event: any) => (handleCommand('/terms-of-api/sdk')))
                  }, [
                    _cache[55] || (_cache[55] = _createElementVNode("div", { class: "line" }, null, -1)),
                    _createElementVNode("a", {
                      onClick: _cache[23] || (_cache[23] = _withModifiers(() => {}, ["left","prevent"])),
                      href: "/terms-of-api/sdk"
                    }, _toDisplayString(_unref(t)("layout.HEADER_SDK_RESTRICTION")), 1)
                  ])
                ]),
                _: 1
              }, 8, ["title"]),
              (isLogin.value)
                ? (_openBlock(), _createBlock(_component_el_collapse_item, {
                    key: 0,
                    title: _unref(t)('layout.MENU_MANAGE_TITLE')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: "sp-menu-item flex align-center",
                        onClick: _cache[26] || (_cache[26] = ($event: any) => (sPToManage('/applications')))
                      }, [
                        _cache[56] || (_cache[56] = _createElementVNode("div", { class: "line" }, null, -1)),
                        _createElementVNode("a", {
                          onClick: _cache[25] || (_cache[25] = _withModifiers(() => {}, ["left","prevent"])),
                          href: "/applications"
                        }, _toDisplayString(_unref(t)("layout.MENU_APPLICATIONS_TITLE")), 1),
                        _cache[57] || (_cache[57] = _createElementVNode("div", null, null, -1))
                      ]),
                      _createElementVNode("div", {
                        class: "sp-menu-item flex align-center sp-menu-sub-item",
                        onClick: _cache[28] || (_cache[28] = ($event: any) => (sPToManage('/applications/apikey-list')))
                      }, [
                        _cache[58] || (_cache[58] = _createElementVNode("div", { class: "line" }, null, -1)),
                        _createElementVNode("a", {
                          onClick: _cache[27] || (_cache[27] = _withModifiers(() => {}, ["left","prevent"])),
                          href: "/applications/apikey-list"
                        }, _toDisplayString(_unref(t)("layout.MENU_API_TITLE")), 1),
                        _cache[59] || (_cache[59] = _createElementVNode("div", null, null, -1))
                      ]),
                      _createElementVNode("div", {
                        class: "sp-menu-item flex align-center sp-menu-sub-item",
                        onClick: _cache[30] || (_cache[30] = ($event: any) => (sPToManage('/applications/oauth-list')))
                      }, [
                        _cache[60] || (_cache[60] = _createElementVNode("div", { class: "line" }, null, -1)),
                        _createElementVNode("a", {
                          onClick: _cache[29] || (_cache[29] = _withModifiers(() => {}, ["left","prevent"])),
                          href: "/applications/oauth-list"
                        }, _toDisplayString(_unref(t)("layout.MENU_OAUTH_TITLE")), 1)
                      ]),
                      _createElementVNode("div", {
                        class: "sp-menu-item flex align-center",
                        onClick: _cache[32] || (_cache[32] = ($event: any) => (sPToManage('/user-setting-edit')))
                      }, [
                        _cache[61] || (_cache[61] = _createElementVNode("div", { class: "line" }, null, -1)),
                        _createElementVNode("a", {
                          onClick: _cache[31] || (_cache[31] = _withModifiers(() => {}, ["left","prevent"])),
                          href: "/user-setting-edit"
                        }, _toDisplayString(_unref(t)("layout.MENU_PROFILE_TITLE")), 1)
                      ])
                    ]),
                    _: 1
                  }, 8, ["title"]))
                : _createCommentVNode("", true),
              _createVNode(_component_el_collapse_item, {
                title: _unref(t)('layout.HEADER_ACCOUNT_MANAGEMENT')
              }, {
                default: _withCtx(() => [
                  (!isLogin.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "sp-menu-item flex align-center",
                        onClick: login
                      }, [
                        _cache[62] || (_cache[62] = _createElementVNode("div", { class: "line" }, null, -1)),
                        _createElementVNode("div", null, _toDisplayString(_unref(t)("layout.HEADER_LOGIN")), 1)
                      ]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("div", {
                          class: "sp-menu-item flex align-center",
                          onClick: _cache[34] || (_cache[34] = ($event: any) => (handleUserCommand('info')))
                        }, [
                          _cache[63] || (_cache[63] = _createElementVNode("div", { class: "line" }, null, -1)),
                          _createElementVNode("a", {
                            onClick: _cache[33] || (_cache[33] = _withModifiers(() => {}, ["left","prevent"])),
                            href: "/user-setting-edit"
                          }, _toDisplayString(_unref(t)("layout.HEADER_INFO")), 1)
                        ]),
                        (
              _unref(store).state.currentAccountType === _unref(AccountType).corporation &&
              _unref(store).state.userinfo?.user_developer
            )
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "sp-menu-item flex align-center",
                              onClick: _cache[35] || (_cache[35] = ($event: any) => (handleUserCommand('user')))
                            }, [
                              _cache[64] || (_cache[64] = _createElementVNode("div", { class: "line" }, null, -1)),
                              _createElementVNode("div", null, _toDisplayString(_unref(t)("layout.HEADER_USER")), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (
              _unref(store).state.currentAccountType === _unref(AccountType).user &&
              _unref(store).state.userinfo?.corporation_developer
            )
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: "sp-menu-item flex align-center",
                              onClick: _cache[36] || (_cache[36] = ($event: any) => (handleUserCommand('corporation')))
                            }, [
                              _cache[65] || (_cache[65] = _createElementVNode("div", { class: "line" }, null, -1)),
                              _createElementVNode("div", null, _toDisplayString(_unref(t)("layout.HEADER_CORPORATION")), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", {
                          class: "sp-menu-item flex align-center",
                          onClick: logout
                        }, [
                          _cache[66] || (_cache[66] = _createElementVNode("div", { class: "line" }, null, -1)),
                          _createElementVNode("div", null, _toDisplayString(_unref(t)("layout.HEADER_LOGOUT")), 1)
                        ])
                      ], 64))
                ]),
                _: 1
              }, 8, ["title"])
            ]),
            _: 1
          }),
          _createElementVNode("div", {
            class: "sp-close-menu",
            onClick: _cache[37] || (_cache[37] = ($event: any) => (handleOpenMenu('close')))
          }, [
            _cache[67] || (_cache[67] = _createElementVNode("span", { class: "Safieicon" }, "", -1)),
            _createElementVNode("span", null, _toDisplayString(_unref(t)("global.CLOSE_BTN")), 1)
          ])
        ], 512))
      : _createCommentVNode("", true)
  ], 64))
}
}

})