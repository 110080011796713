import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer-box" }
const _hoisted_2 = { class: "footer-top-part" }
const _hoisted_3 = { class: "footer-top-content" }
const _hoisted_4 = { class: "top-part-item" }
const _hoisted_5 = { class: "top-part-item" }
const _hoisted_6 = { class: "top-part-item" }
const _hoisted_7 = { class: "top-part-item" }
const _hoisted_8 = { class: "top-part-item" }
const _hoisted_9 = {
  href: "https://safie.jp/contact/api/",
  class: "part-item-text",
  target: "_blank"
}
const _hoisted_10 = { class: "footer-middle-part" }
const _hoisted_11 = { class: "content-left" }
const _hoisted_12 = { class: "content-context" }
const _hoisted_13 = { class: "footer-bottom-part" }
const _hoisted_14 = {
  class: "bottom-part-item",
  href: "https://safie.co.jp/company/profile/",
  target: "_blank"
}
const _hoisted_15 = { class: "bottom-part-text" }
const _hoisted_16 = {
  class: "bottom-part-item",
  href: "/terms-of-service",
  target: "_blank"
}
const _hoisted_17 = { class: "bottom-part-text" }
const _hoisted_18 = {
  class: "bottom-part-item",
  href: "https://safie.co.jp/privacy/",
  target: "_blank"
}
const _hoisted_19 = { class: "bottom-part-text" }
const _hoisted_20 = {
  class: "bottom-part-item",
  href: "https://safie.link/cookie/",
  target: "_blank"
}
const _hoisted_21 = { class: "bottom-part-text" }
const _hoisted_22 = {
  class: "bottom-part-item",
  href: "https://safie.co.jp/security/",
  target: "_blank"
}
const _hoisted_23 = { class: "bottom-part-text" }

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'Footer',
  setup(__props) {

const { t } = useI18n();
const router = useRouter();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(router).push({ path: '/' })), ["left","prevent"])),
            class: "part-item-text",
            href: "/"
          }, _toDisplayString(_unref(t)("layout.FOOTER_HOME")), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_unref(router).push({ path: '/tutorial/developers' })), ["left","prevent"])),
            class: "part-item-text",
            href: "/tutorial/developers"
          }, _toDisplayString(_unref(t)("layout.FOOTER_TUTORIAL_DEVELOPERS")), 1),
          _createElementVNode("a", {
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_unref(router).push({ path: '/tutorial/oauth' })), ["left","prevent"])),
            class: "part-item-text",
            href: "/tutorial/oauth"
          }, _toDisplayString(_unref(t)("layout.FOOTER_OAUTH_TITLE")), 1),
          _createElementVNode("a", {
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_unref(router).push({ path: '/tutorial/api-key' })), ["left","prevent"])),
            class: "part-item-text",
            href: "/tutorial/api-key"
          }, _toDisplayString(_unref(t)("layout.FOOTER_API_TITLE")), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("a", {
            onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_unref(router).push({ path: '/guidelines' })), ["left","prevent"])),
            class: "part-item-text",
            href: "/guidelines"
          }, _toDisplayString(_unref(t)("layout.FOOTER_API_DESIGN")), 1),
          _createElementVNode("a", {
            onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_unref(router).push({ path: '/reference/api' })), ["left","prevent"])),
            class: "part-item-text",
            href: "/reference/api"
          }, _toDisplayString(_unref(t)("layout.FOOTER_API_REFERENCE")), 1),
          _createElementVNode("a", {
            onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_unref(router).push({ path: '/terms-of-api' })), ["left","prevent"])),
            class: "part-item-text",
            href: "/terms-of-api"
          }, _toDisplayString(_unref(t)("layout.FOOTER_API_RESTRICTION")), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("a", {
            onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_unref(router).push({ path: '/guidelines/sdk' })), ["left","prevent"])),
            class: "part-item-text",
            href: "/guidelines/sdk"
          }, _toDisplayString(_unref(t)("layout.FOOTER_SDK_DESIGN")), 1),
          _createElementVNode("a", {
            onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_unref(router).push({ path: '/reference/sdk' })), ["left","prevent"])),
            class: "part-item-text",
            href: "/reference/sdk"
          }, _toDisplayString(_unref(t)("layout.FOOTER_SDK_REFERENCE")), 1),
          _createElementVNode("a", {
            onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_unref(router).push({ path: '/terms-of-api/sdk' })), ["left","prevent"])),
            class: "part-item-text",
            href: "/terms-of-api/sdk"
          }, _toDisplayString(_unref(t)("layout.FOOTER_SDK_RESTRICTION")), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("a", {
            onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_unref(router).push({ path: '/faq' })), ["left","prevent"])),
            class: "part-item-text",
            href: "/faq"
          }, _toDisplayString(_unref(t)("layout.FOOTER_FAQ")), 1),
          _createElementVNode("a", _hoisted_9, [
            _createTextVNode(_toDisplayString(_unref(t)("layout.FOOTER_CONSULT")) + " ", 1),
            _cache[13] || (_cache[13] = _createElementVNode("span", { class: "Safieicon bottom-part-icon" }, "", -1))
          ]),
          _createElementVNode("a", {
            onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_unref(router).push({ path: '/casestudy' })), ["left","prevent"])),
            class: "part-item-text",
            href: "/casestudy"
          }, _toDisplayString(_unref(t)("layout.FOOTER_CASE")), 1),
          _createElementVNode("a", {
            onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (_unref(router).push({ path: '/releasenotes' })), ["left","prevent"])),
            class: "part-item-text",
            href: "/releasenotes"
          }, _toDisplayString(_unref(t)("layout.FOOTER_RELEASE_NOTES")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _cache[14] || (_cache[14] = _createElementVNode("span", { class: "Safieicon content-icon" }, "", -1)),
        _createElementVNode("span", _hoisted_12, [
          _createElementVNode("div", null, _toDisplayString(_unref(t)("layout.FOOTER_COMPANY")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("a", _hoisted_14, [
        _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(t)("layout.FOOTER_OPERATING_PROFILE")), 1),
        _cache[15] || (_cache[15] = _createElementVNode("span", { class: "Safieicon bottom-part-icon" }, "", -1))
      ]),
      _createElementVNode("a", _hoisted_16, [
        _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(t)("layout.FOOTER_API_PROFILE")), 1),
        _cache[16] || (_cache[16] = _createElementVNode("span", { class: "Safieicon bottom-part-icon" }, "", -1))
      ]),
      _createElementVNode("a", _hoisted_18, [
        _createElementVNode("span", _hoisted_19, _toDisplayString(_unref(t)("signup.IS_AGREE_LINK2")), 1),
        _cache[17] || (_cache[17] = _createElementVNode("span", { class: "Safieicon bottom-part-icon" }, "", -1))
      ]),
      _createElementVNode("a", _hoisted_20, [
        _createElementVNode("span", _hoisted_21, _toDisplayString(_unref(t)("layout.FOOTER_COOKIE_LINK")), 1),
        _cache[18] || (_cache[18] = _createElementVNode("span", { class: "Safieicon bottom-part-icon" }, "", -1))
      ]),
      _createElementVNode("a", _hoisted_22, [
        _createElementVNode("span", _hoisted_23, _toDisplayString(_unref(t)("layout.FOOTER_GUIDE_LINK")), 1),
        _cache[19] || (_cache[19] = _createElementVNode("span", { class: "Safieicon bottom-part-icon" }, "", -1))
      ])
    ]),
    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "footer-bottom-company" }, [
      _createElementVNode("span", null, "©︎"),
      _createElementVNode("span", null, "Safie inc.")
    ], -1))
  ]))
}
}

})